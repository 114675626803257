<template lang="">
    <div>
        <List />
    </div>
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

export default {
    name:"kiracılar",
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: 'Kiracılar', route: '/kiracilar' },
        ]);
    },
    components:{
        List: () => import("@/components/kiracilar/List")
    }
}
</script>
<style lang="">
    
</style>